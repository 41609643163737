import type { User, Label as LabelModel, Issue, ProjectAttachment, Project } from "./types";

// Shared Issue shape
const issueFragment = `
id
identifier
title
url
priorityLabel
createdAt
updatedAt
labels {
  nodes {
    id
    name
    color
  }
}
cycle {
  id
  number
  startsAt
  endsAt
  completedAt
}
project {
  name
}
team {
  id
  name
}
state {
  id
  name
  color
}
assignee {
  id
  avatarUrl
  name
}
customerTicketCount
`;

/** Project fragment. */
const projectFragment = `
id
name
url
createdAt
updatedAt
teams {
  nodes {
    id
    name
    key
  }
}
status {
  id
  name
  color
}
priorityLabel
`;

/** Query to get a team and it’s members and labels. */
export const TeamQuery = `
  query ($teamId: String!) {
    team(id: $teamId) {
      id
      name
      labels(first: 250) {
        nodes {
          id
          name
          color
          parent {
            id
            name
            color
          }
        }
      }
      members(first: 250) {
        nodes {
          id
          name
          email
          avatarUrl
        }
      }
    }
  }
`;
/** TeamQuery shape. */
export interface ITeamQuery {
  team?: {
    id: string;
    name: string;
    labels: {
      nodes: LabelModel[];
    };
    members: {
      nodes: User[];
    };
  };
}

/** Query to search issues. */
export const SearchIssueQuery = `
  query ($searchQuery: String!, $includeComments: Boolean!) {
    searchIssues(term: $searchQuery, includeComments: $includeComments, first: 25) {
      nodes {
        ${issueFragment}
      }
    }
  }
`;
/** SearchIssueQuery shape. */
export interface ISearchIssueQuery {
  searchIssues: {
    nodes: Issue[];
  };
}

/** Query to search issues and projects. */
export const CombinedSearchQuery = `
  query ($searchQuery: String!, $includeComments: Boolean!) {
    searchIssues(term: $searchQuery, includeComments: $includeComments, first: 25) {
      nodes {
        ${issueFragment}
      }
    }
    searchProjects(term: $searchQuery, first: 25) {
      nodes {
        ${projectFragment}
      }
    }
  }
`;

/** CombinedSearchQuery shape. */
export interface ICombinedSearchQuery {
  searchIssues: {
    nodes: Issue[];
  };
  searchProjects: {
    nodes: Project[];
  };
}

/** Create new issue mutation. */
export const CreateIssueMutation = `
  mutation IssueCreateMutation(
    $teamId: String!
    $title: String!
    $description: String
    $assigneeId: String
    $labelIds: [String!]
    $priority: Int
  ) {
    issueCreate(input: {
      teamId: $teamId
      title: $title
      description: $description
      assigneeId: $assigneeId
      labelIds: $labelIds
      priority: $priority
    }) {
      success
      issue {
        ${issueFragment}
      }
    }
  }
`;
/** IssueCreateMutation shape. */
export interface ICreateIssueMutation {
  issueCreate: {
    success: boolean;
    issue?: Issue;
  };
}
/** Update issue mutation. */
export const UpdateIssueMutation = `
mutation IssueUpdateMutation($issueUpdateId: String!, $description: String!) {
  issueDescriptionUpdateFromFront(id: $issueUpdateId, description: $description) {
      success
      issue {
        ${issueFragment}
      }
    }
  }
`;
/** IssueUpdateMutation shape. */
export interface IIssueUpdateMutation {
  issueUpdate: {
    success: boolean;
    issue?: Issue;
  };
}

/** Create new issue attachment mutation. */
export const CreateAttachmentMutation = `
  mutation IssueAttachmentMutation(
    $issueId: String!
    $title: String!
    $subtitle: String
    $url: String!
    $metadata: JSONObject
    $commentBody: String
  ) {
    attachmentCreate(input: {
      issueId: $issueId
      title: $title
      subtitle: $subtitle
      url: $url
      metadata: $metadata
      commentBody: $commentBody
    }) {
      success
      attachment {
        id
      }
    }
  }
`;

/** CreateAttachmentMutation shape. */
export interface ICreateAttachmentMutation {
  attachmentCreate: {
    success: boolean;
    attachment?: {
      id: string;
    };
  };
}

/** Create new project attachment mutation. */
export const CreateProjectAttachmentMutation = `
  mutation ProjectAttachmentMutation(
    $projectId: String!
    $title: String!
    $subtitle: String
    $url: String!
    $metadata: JSONObject
    $noteBody: String
  ) {
    projectAttachmentCreate(input: {
      projectId: $projectId
      title: $title
      subtitle: $subtitle
      url: $url
      metadata: $metadata
      noteBody: $noteBody
    }) {
      success
      attachment {
        id
      }
    }
  }
`;

/** CreateProjectAttachmentMutation shape. */
export interface ICreateProjectAttachmentMutation {
  projectAttachmentCreate: {
    success: boolean;
    attachment?: {
      id: string;
    };
  };
}

/** Create new issue attachment mutation. */
export const DeleteAttachmentMutation = `
  mutation IssueAttachmentMutation(
    $id: String!
  ) {
    attachmentDelete(id: $id) {
      success
    }
  }
`;

/** DeleteAttachmentMutation shape. */
export interface IDeleteAttachmentMutation {
  attachmentDelete: {
    success: boolean;
  };
}

/** Delete project attachment mutation. */
export const DeleteProjectAttachmentMutation = `
  mutation ProjectAttachmentMutation(
    $id: String!
  ) {
    projectAttachmentDelete(id: $id) {
      success
    }
  }
`;

export interface IDeleteProjectAttachmentMutation {
  projectAttachmentDelete: {
    success: boolean;
  };
}

/** Create new issue comment mutation. */
export const CreateCommentMutation = `
  mutation IssueCommentMutation(
    $issueId: String!
    $body: String!
  ) {
    commentCreate(input: {
      issueId: $issueId
      body: $body
    }) {
      success
      comment {
        id
      }
    }
  }
`;

/** CreateCommentMutation shape. */
export interface ICreateCommentMutation {
  commentCreate: {
    success: boolean;
    comment?: {
      id: string;
    };
  };
}

/** Query current user and their teams. */
export const ViewerQuery = `
  query {
    viewer {
      id
      name
      email
      avatarUrl
    }
    teams(last: 250) {
      nodes {
        id
        name
        displayName
      }
    }
    issuePriorityValues {
      priority
      label
    }
    organization {
      customersEnabled
    }
    customerRequestsOnProjectsFlag: flag(flag: "customer-requests-on-projects"){
      value
    }
  }
`;
export interface IViewerQuery {
  viewer: User;
  teams: {
    nodes: {
      id: string;
      name: string;
      displayName: string;
    }[];
  };
  issuePriorityValues: {
    priority: number;
    label: string;
  }[];
  organization: {
    customersEnabled: boolean;
  };
  customerRequestsOnProjectsFlag: {
    value: boolean;
  };
}

/** Get attachments by its URL */
export const AttachmentsQuery = `
  query AttachmentQuery($ticketUrl: String!) {
    attachmentsForURL(url: $ticketUrl) {
      nodes {
        id
        issue {
          ${issueFragment}
        }
      }
    }
    projectAttachments(filter: {url:{eq:$ticketUrl}}) {
    nodes {
      id
      createdAt
      project {
        ${projectFragment}
      }
    }
    }
  }
`;

/** Issue label. */
export type Label = {
  id: string;
  name: string;
  color: string;
};

/** Issue attachment. */
export type Attachment = {
  id: string;
  issue: Issue;
};

/** Shape of the attachment query. */
export interface IAttachmentsQuery {
  attachmentsForURL: {
    nodes: Attachment[];
  };
  projectAttachments: {
    nodes: ProjectAttachment[];
  };
}
