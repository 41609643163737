import React from "react";
import styled from "styled-components";
import TimeAgo from "javascript-time-ago";
import { useMutation } from "urql";
import Front from "@frontapp/plugin-sdk";
import { Text } from "@linear/orbiter/components/Text";
import { Avatar } from "@linear/orbiter/components/Avatar";
import { fontWeight } from "@linear/orbiter/styles/mixins";
import IssueIcon from "~/icons/IssueIcon";
import type { Issue } from "~/types";
import { DeleteAttachmentMutation, type IDeleteAttachmentMutation } from "../queries";
import { Label as IssueLabel } from "./Label";
import { FullViewPrompt } from "./FullViewPrompt";
import { Link } from "./Link";

const timeAgo = new TimeAgo("en-US");

type Props = {
  // Attachment ID
  attachmentId?: string;
  // Linear issue to be shown.
  issue: Issue;
  // Should unlinking be allowed
  allowUnlink?: boolean;
  // Callback to be executed after unlinking the attachment
  onUnlink?(): void;
};

export const IssueWidget = (props: Props) => {
  const { attachmentId, issue, allowUnlink, onUnlink } = props;
  const createdAt = new Date(issue.createdAt);
  const updatedAt = new Date(issue.updatedAt);
  const [confirmUnlink, setConfirmUnlink] = React.useState(false);

  const [, deleteAttachment] = useMutation<IDeleteAttachmentMutation>(DeleteAttachmentMutation);

  const handleUnlink = (event: React.MouseEvent) => {
    event.preventDefault();
    setConfirmUnlink(true);
  };

  const processUnlink = () => {
    if (attachmentId && onUnlink) {
      setConfirmUnlink(false);
      void deleteAttachment({ id: attachmentId }).then(onUnlink);
    }
  };

  return (
    <Container>
      {confirmUnlink && (
        <FullViewPrompt
          title="Unlink this issue?"
          description="Linear issue won't be deleted."
          confirmLabel="Unlink ticket"
          onConfirm={processUnlink}
          onCancel={() => setConfirmUnlink(false)}
        />
      )}
      <Header>
        <IssueIcon style={{ marginRight: 8 }} />
        <Link
          as="a"
          href={issue.url}
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            Front.openUrl(issue.url);
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {issue.identifier}
        </Link>
        {allowUnlink && (
          <>
            <Separator>&middot;</Separator>
            <Link onClick={handleUnlink}>Unlink</Link>
          </>
        )}
      </Header>
      <Title>{issue.title}</Title>
      <Table>
        <Row>
          <Label>Status</Label>
          <Value>{issue.state.name}</Value>
        </Row>
        <Row>
          <Label>Priority</Label>
          <Value>{issue.priorityLabel}</Value>
        </Row>
        <Row>
          <Label>Assignee</Label>
          <Value>
            {issue.assignee ? (
              <>
                <Avatar
                  src={issue.assignee.avatarUrl}
                  size="small"
                  modelId={issue.assignee.id}
                  name={issue.assignee.name}
                  style={{ marginRight: 4 }}
                />
                {issue.assignee.name}
              </>
            ) : (
              <EmptyValue>Unassigned</EmptyValue>
            )}
          </Value>
        </Row>
        <Row>
          <Label>Labels</Label>
          <Column>
            <Labels>
              {issue.labels.nodes.length ? (
                issue.labels.nodes.map(label => <IssueLabel label={label} key={label.id} />)
              ) : (
                <EmptyValue>No labels</EmptyValue>
              )}
            </Labels>
          </Column>
        </Row>
        {issue.cycle && (
          <Row>
            <Label>Cycle</Label>
            <Value>
              {issue.cycle.name ? `${issue.cycle.name} (Cycle ${issue.cycle.number})` : `Cycle ${issue.cycle.number}`}
            </Value>
          </Row>
        )}
        {issue.project && (
          <Row>
            <Label>Project</Label>
            <Value>{issue.project.name}</Value>
          </Row>
        )}
        <Row>
          <Label>Ticket count</Label>
          <Value>{issue.customerTicketCount}</Value>
        </Row>
      </Table>
      <Updated>Created {timeAgo.format(createdAt, "round")}</Updated>
      {issue.createdAt !== issue.updatedAt && <Updated>Updated {timeAgo.format(updatedAt, "round")}</Updated>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Separator = styled.span`
  padding: 0 6px;
  color: #777777;
`;

const Title = styled.div`
  font-weight: ${fontWeight("medium")};
  margin-bottom: 12px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Label = styled(Text).attrs({
  variant: "mini",
  color: "labelMuted",
})`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Value = styled(Text).attrs({
  variant: "miniPlus",
  color: "labelTitle",
})`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Row = styled.div`
  display: flex;

  > ${Label}:first-child {
    flex: 0.5;
  }
`;

const Labels = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Updated = styled.span`
  font-size: 12px;
  color: #90959d;
`;

const EmptyValue = styled.span`
  color: #777777;
`;
