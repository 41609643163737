import React from "react";
import styled from "styled-components";
import TimeAgo from "javascript-time-ago";
import { useMutation } from "urql";
import Front from "@frontapp/plugin-sdk";
import { Text } from "@linear/orbiter/components/Text";
import { fontWeight } from "@linear/orbiter/styles/mixins";
import type { Project } from "~/types";
import { en } from "~/utils/timeAgoLocaleEn";
import ProjectIcon from "~/icons/ProjectIcon";
import { DeleteProjectAttachmentMutation, type IDeleteProjectAttachmentMutation } from "../queries";
import { FullViewPrompt } from "./FullViewPrompt";
import { Link } from "./Link";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

type Props = {
  // Attachment ID
  attachmentId?: string;
  // Linear project to be shown.
  project: Project;
  // Should unlinking be allowed
  allowUnlink?: boolean;
  // Callback to be executed after unlinking the attachment
  onUnlink?(): void;
};

export const ProjectWidget = (props: Props) => {
  const { attachmentId, project, allowUnlink, onUnlink } = props;
  const createdAt = new Date(project.createdAt);
  const [confirmUnlink, setConfirmUnlink] = React.useState(false);

  const [, deleteAttachment] = useMutation<IDeleteProjectAttachmentMutation>(DeleteProjectAttachmentMutation);

  const handleUnlink = (event: React.MouseEvent) => {
    event.preventDefault();
    setConfirmUnlink(true);
  };

  const processUnlink = () => {
    if (attachmentId && onUnlink) {
      setConfirmUnlink(false);
      void deleteAttachment({ id: attachmentId }).then(onUnlink);
    }
  };

  return (
    <Container>
      {confirmUnlink && (
        <FullViewPrompt
          title="Unlink this project?"
          description="Linear project won't be deleted."
          confirmLabel="Unlink project"
          onConfirm={processUnlink}
          onCancel={() => setConfirmUnlink(false)}
        />
      )}
      <Header>
        <ProjectIcon style={{ marginRight: 8 }} />
        <Link
          as="a"
          href={project.url}
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            Front.openUrl(project.url);
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {project.name}
        </Link>
        {allowUnlink && (
          <>
            <Separator>&middot;</Separator>
            <Link onClick={handleUnlink}>Unlink</Link>
          </>
        )}
      </Header>
      <Title>{project.name}</Title>
      <Table>
        <Row>
          <Label>Status</Label>
          <Value>{project.status.name}</Value>
        </Row>
        <Row>
          <Label>Priority</Label>
          <Value>{project.priorityLabel}</Value>
        </Row>
      </Table>
      <Updated>Created {timeAgo.format(createdAt, "round")}</Updated>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Separator = styled.span`
  padding: 0 6px;
  color: #777777;
`;

const Title = styled.div`
  font-weight: ${fontWeight("medium")};
  margin-bottom: 12px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Label = styled(Text).attrs({
  variant: "mini",
  color: "labelMuted",
})`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Value = styled(Text).attrs({
  variant: "miniPlus",
  color: "labelTitle",
})`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px 2px;
`;

const Row = styled.div`
  display: flex;

  > ${Label}:first-child {
    flex: 0.5;
  }
`;

const Updated = styled.span`
  font-size: 12px;
  color: #90959d;
`;
