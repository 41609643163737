import React from "react";
import ReactSelect, { type OptionTypeBase, type Props as ReactSelectProps } from "react-select";
import styled, { css } from "styled-components";
import AsyncReactSelect, { type Props as AsyncReactSelectProps } from "react-select/async";
import { ChevronIcon } from "@linear/orbiter/icons/base/ChevronIcon";

const StyledChevronIcon = styled(ChevronIcon)`
  margin-right: 8px;
`;
// Proxy to drop passed props
const DropdownIndicator = () => <StyledChevronIcon />;

/** Shared props */
export const defaultSelectProps = {
  defaultOptions: true,
  classNamePrefix: "react-select",
  components: {
    DropdownIndicator,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } as any,
};

const selectCss = css`
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__input input.focus-visible {
    box-shadow: none;
  }

  .react-select__menu {
    background: ${props => props.theme.color.bgBase};
    border: 1px solid ${props => props.theme.color.bgBorderSolid};
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
    border-radius: ${props => props.theme.inputBorderRadius};
    margin: 4px 0;
  }

  .react-select__control--is-disabled {
    border: 1px solid ${props => props.theme.color.bgBorder};

    .react-select__indicators svg {
      fill: ${props => props.theme.color.labelFaint};
    }
  }

  .react-select__option,
  .react-select__menu-notice {
    height: 32px;
    font-size: ${props => props.theme.inputFontSize};
    color: ${props => props.theme.color.labelTitle};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .react-select__option--is-focused {
    background: ${props => props.theme.color.bgShade};
  }

  .react-select__option--is-selected,
  .react-select__option--is-selected:active {
    background: ${props => props.theme.color.bgSelectedHover};
  }

  .react-select__menu-notice {
    justify-content: center;
    color: ${props => props.theme.color.labelFaint};
  }

  .react-select__control {
    min-height: auto;
    background: ${props => props.theme.inputBackground};
    border: 1px solid ${props => props.theme.color.bgBorderSolid};
    border-radius: ${props => props.theme.inputBorderRadius};
    font-size: ${props => props.theme.inputFontSize};
    color: ${props => props.theme.color.labelTitle};
    margin: 0;
    cursor: pointer;

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      ${StyledChevronIcon} {
        fill: ${props => props.theme.color.labelTitle};
      }
    }
  }

  .react-select__control--is-focused {
    border-color: transparent;
    box-shadow: ${props => props.theme.focusShadow};

    ${StyledChevronIcon} {
      fill: ${props => props.theme.color.labelTitle};
    }
  }
`;

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly SelectOption[];
}

export interface SelectOption {
  readonly value: string;
  readonly label: string;
  readonly type: "issue" | "project";
}

const SelectComponent = styled(ReactSelect)`
  ${selectCss}
`;
const AsyncSelectComponent = styled(AsyncReactSelect)`
  ${selectCss}
`;

/**
 * React-select component styled for Linear.
 *
 * IMPORTANT: This component should not be used inside @linear/client as it uses emotion.
 *
 * @see https://react-select.com/home
 */
export const Select = <T extends {}, K extends boolean>(props: SelectProps<T, K>) => {
  const { theme, ...rest } = props;
  return <SelectComponent {...defaultSelectProps} {...rest} />;
};
export type SelectProps<T extends OptionTypeBase, K extends boolean> = ReactSelectProps<T, K>;

/**
 * Async react-select component styled for Linear.
 *
 * IMPORTANT: This component should not be used inside @linear/client as it uses emotion.
 *
 * @see https://react-select.com/home
 */
export const AsyncSelect = <T extends {}, K extends boolean>(props: AsyncSelectProps<T, K>) => {
  const { theme, ...rest } = props;
  return <AsyncSelectComponent {...defaultSelectProps} {...rest} formatGroupLabel={formatGroupLabel} />;
};
export type AsyncSelectProps<T extends OptionTypeBase, K extends boolean> = AsyncReactSelectProps<T, K>;
