import React from "react";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { Link } from "./Link";

type Props = {
  error: string;
  onReload(): void;
  onLogout(): void;
};

export const ErrorView = (props: Props) => {
  const { onReload, onLogout } = props;
  let error = props.error;
  error = error.replace("[GraphQL]", "").trim();

  return (
    <Flex gap={12} column style={{ padding: 24 }}>
      <div>
        <Text variant="smallPlus" color="labelBase">
          Error occurred: {error}
        </Text>
      </div>
      <div>
        <Link onClick={onReload}>Reload</Link>{" "}
        <Text variant="smallPlus" color="labelMuted">
          &middot;
        </Text>{" "}
        <Link onClick={onLogout}>Log out</Link>
      </div>
    </Flex>
  );
};
