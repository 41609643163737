import * as React from "react";

const ProjectIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M11.9267 13.2316L10.5733 14.012C9.63586 14.5527 9.16712 14.823 8.66878 14.9288C8.22785 15.0224 7.77215 15.0224 7.33122 14.9288C6.83288 14.823 6.36415 14.5527 5.42668 14.0121L4.07333 13.2316C3.13585 12.6909 2.66712 12.4206 2.32622 12.0424C2.02459 11.7078 1.79673 11.3136 1.65743 10.8854C1.5 10.4014 1.5 9.86076 1.5 8.77949L1.5 7.21856C1.5 6.13728 1.5 5.59664 1.65744 5.11266C1.79673 4.68442 2.02459 4.29022 2.32622 3.9556C2.66712 3.57742 3.13586 3.3071 4.07333 2.76646L5.42667 1.986C6.36414 1.44536 6.83288 1.17504 7.33122 1.06924C7.77215 0.975619 8.22785 0.975619 8.66878 1.06924C9.16712 1.17504 9.63586 1.44536 10.5733 1.986L11.9267 2.76646C12.8641 3.3071 13.3329 3.57742 13.6738 3.9556C13.9754 4.29022 14.2033 4.68442 14.3426 5.11266C14.5 5.59664 14.5 6.13728 14.5 7.21856V8.77949C14.5 9.86077 14.5 10.4014 14.3426 10.8854C14.2033 11.3136 13.9754 11.7078 13.6738 12.0424C13.3329 12.4206 12.8641 12.6909 11.9267 13.2316ZM6.17603 12.7126C6.71825 13.0253 7.03819 13.2042 7.25053 13.31L7.25053 9.85305C7.25053 9.00845 6.77761 8.23569 6.02717 7.85093L3.00637 6.34053C3.00178 6.5576 3 6.84075 3 7.21856L3 8.77949C3 9.96205 3.01746 10.2173 3.08387 10.4214C3.15808 10.6495 3.2795 10.8597 3.44037 11.0381C3.58448 11.198 3.79754 11.341 4.8227 11.9322L6.17603 12.7126ZM8.7506 13.3094C8.96295 13.2036 9.28271 13.0248 9.82397 12.7126L11.1773 11.9322C12.2025 11.341 12.4155 11.198 12.5596 11.0381C12.7205 10.8597 12.8419 10.6495 12.9161 10.4214C12.9825 10.2173 13 9.96205 13 8.77949V7.21856C13 6.84101 12.9982 6.55799 12.9936 6.34097L9.98747 7.84406C9.2292 8.22643 8.75061 9.00339 8.75061 9.85305L8.7506 13.3094ZM12.5081 4.90669C12.3582 4.7628 12.0887 4.59146 11.1773 4.06587L9.82397 3.2854C8.79895 2.69428 8.56827 2.58133 8.35725 2.53653C8.12172 2.48652 7.87828 2.48652 7.64275 2.53653C7.43173 2.58133 7.20105 2.69428 6.17603 3.2854L4.8227 4.06587C3.91211 4.591 3.64224 4.7625 3.4923 4.90631L6.99422 6.6574C7.62765 6.97412 8.37324 6.97412 9.00668 6.6574L12.5081 4.90669Z"
      fill="#1B1B1B"
    />
  </svg>
);
export default ProjectIcon;
