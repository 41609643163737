const LinkIcon = (props: {}) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.75A3.75 3.75 0 013.75 0H5a.75.75 0 010 1.5H3.75A2.25 2.25 0 001.5 3.75v5.5a2.25 2.25 0 002.25 2.25h6.5a2.25 2.25 0 002.25-2.25V7A.75.75 0 0114 7v2.25A3.75 3.75 0 0110.25 13h-6.5A3.75 3.75 0 010 9.25v-5.5zm12.447-2.19L11.44.555a1.9 1.9 0 00-2.683 0l-1.342 1.34a1.426 1.426 0 00-.308 1.551.474.474 0 10.876-.362.474.474 0 01.103-.515l1.341-1.341a.949.949 0 011.342 0l1.006 1.005a.949.949 0 010 1.342l-1.343 1.34a.481.481 0 01-.517.102.474.474 0 10-.363.877 1.435 1.435 0 001.55-.309l1.342-1.341a1.9 1.9 0 00.002-2.684zM6.512 7a.517.517 0 01-.362-.148.512.512 0 010-.724l3.982-3.984a.512.512 0 01.724.724L6.874 6.852A.517.517 0 016.512 7zm1.607-.602a.474.474 0 01.773.154 1.427 1.427 0 01-.308 1.551L7.242 9.445a1.896 1.896 0 01-2.681 0L3.554 8.439a1.9 1.9 0 010-2.683l1.343-1.342a1.438 1.438 0 011.55-.309.474.474 0 01-.362.877.48.48 0 00-.517.103L4.225 6.426a.949.949 0 000 1.342L5.23 8.774a.948.948 0 001.34 0l1.342-1.342a.474.474 0 00.103-.517.475.475 0 01.103-.517z"
      />
    </svg>
  );
};

export default LinkIcon;
