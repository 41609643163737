import styled, { css } from "styled-components";
import { thinBorderThemed, thinPixel } from "@linear/orbiter/styles/mixins";
import { Browser } from "../utils/browser";
import { fontSize } from "../styles/mixins";
import { highlight, transitionSpeed } from "./../styles/styled";

/** Props for the Input */
export interface InputProps {
  /** In what size should the input be rendered. Defaults to normal */
  appearance?: "normal" | "small" | "medium" | "large" | "xlarge";
  /** Should the input be stretched (width: 100%). Defaults to false */
  stretched?: boolean;
  /** Should password managers be allowed to autofill this input. Defaults to false */
  allowPMAutofill?: boolean;
  /** Should the input have a border. Defaults to true */
  noBorder?: boolean;
}

/**
 * A input field component.
 */
export const Input = styled("input").attrs<InputProps>(
  props =>
    ({
      "data-1p-ignore": props.allowPMAutofill !== true,
    }) as InputProps
)<InputProps>`
  height: ${Browser.isPureTouchDevice ? 36 : 32}px;
  padding: ${props => props.theme.inputPadding};
  background: ${props => props.theme.inputBackground};
  border: ${props => (props.noBorder ? `${thinPixel()} solid transparent` : thinBorderThemed("bgBorderSolid")(props))};
  border-radius: ${props => props.theme.inputBorderRadius};
  font-size: ${props => props.theme.inputFontSize};
  color: ${props => props.theme.color.labelTitle};
  margin: 0;
  appearance: none;
  transition: border ${transitionSpeed("highlightFadeOut")};
  -webkit-app-region: no-drag;

  &:disabled {
    color: ${props => props.theme.color.labelMuted};
  }

  &::placeholder {
    color: ${props => props.theme.color.labelFaint};
  }

  &:not(:disabled):${highlight} {
    border-color: ${props =>
      props.noBorder ? "transparent" : props.theme.highlightVariant(props.theme.color.bgBorderSolid)};
    transition-duration: ${transitionSpeed("highlightFadeIn")};
  }

  ${props => (props.type !== "checkbox" && props.type !== "radio" ? `outline-offset: -1px;` : null)}

  ${props =>
    props.type === "checkbox"
      ? `
    height: auto;
  `
      : ""};

  ${props =>
    props.appearance === "small"
      ? `
    font-size: ${fontSize("mini")};
    height: 26px;
  `
      : ""};

  ${props =>
    props.appearance === "medium"
      ? `
      font-size: ${fontSize("mini")};
      height: 28px;
  `
      : ""};

  ${props =>
    props.appearance === "large"
      ? `
    height: 44px;
    padding: 12px;
    border-radius: 6px;
  `
      : ""};

  ${props =>
    props.appearance === "xlarge"
      ? `
    height: 48px;
    padding: 12px;
    border-radius: 6px;
  `
      : ""};

  ${props => (props.stretched ? `width: 100%;` : ``)};

  /* There's no reliable way for us to opt out for lastpass like 1Password's data-1p-ignore, so we have to do this manually */
  ${props =>
    props.allowPMAutofill
      ? ""
      : css`
          + [data-lastpass-icon-root] {
            display: none;
          }
        `}
`;

/**
 * A text area component.
 */
export const Textarea = styled(Input).attrs({ as: "textarea" })`
  appearance: none;
  height: 64px;
  resize: none;
`;

/**
 * A select component.
 */
export const NativeSelect = styled(Input).attrs({ as: "select" })`
  height: ${Browser.isPureTouchDevice ? 36 : 32}px;
  padding: 6px 24px 6px 12px;
  border: ${thinBorderThemed("bgBorderSolid")};
  border-radius: ${props => props.theme.inputBorderRadius};
  font-size: ${props => props.theme.inputFontSize};
  color: ${props => props.theme.color.labelTitle};
  margin: 0;
  appearance: none;
  transition: border ${transitionSpeed("highlightFadeOut")};

  // prettier-ignore
  &:${highlight} {
    background-color: ${props => props.theme.color.controlSecondaryHover};
    transition-duration: ${transitionSpeed("highlightFadeIn")};
  }

  background-color: ${props => props.theme.color.controlSecondary};
  background-image: url("${props => caret(props.theme.color.labelMuted)}");
  background-position: ${Browser.isPureTouchDevice ? `calc(100% + 6px) 14px` : `calc(100% + 4px) 12px`};
  background-size: 24px 24px;
  background-repeat: no-repeat;

  ${props =>
    props.appearance === "small"
      ? `
    font-size: ${fontSize("mini")};
    padding: 3px 24px 3px 12px;
    height: 24px;
    background-position: calc(100% + 4px) 9px;
    background-size: 20px 20px;
  `
      : ""};
`;

/**
 * A Checkbox.
 */
export const Checkbox = styled(Input).attrs({ type: "checkbox" })<{ indeterminate?: boolean }>`
  -webkit-appearance: none;
  background-color: transparent;
  flex-shrink: 0;
  height: 14px;
  width: 14px;
  padding: 2px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  transition: all 80ms ease-out;
  fill-opacity: 0;
  border: 1px solid ${props => props.theme.color.labelFaint};
  border-radius: 3px;
  background-position: 1px 2px;
  box-shadow: none;

  ${props =>
    props.checked &&
    `background-color: ${props.theme.color.controlPrimary};
     border-color: ${props.theme.color.controlPrimary};
     background-image: url("${checkmark(props.theme.color.controlLabel)}");
     background-repeat: no-repeat;
     border: 1px solid ${props.theme.color.controlPrimary};
   `};

  ${props =>
    props.indeterminate &&
    `background-color: ${props.theme.color.labelFaint};
     border-color: ${props.theme.color.labelFaint};
     background-image: url("${dash(props.theme.color.controlLabel)}");
     background-repeat: no-repeat;
     border: 1px solid ${props.theme.color.labelFaint};
     background-position: 3px 5px;
   `};

  &:disabled {
    opacity: 0.5;
    background-color: ${props => props.theme.color.bgBorderSolidThin};
    border-color: ${props => props.theme.color.labelFaint};
  }

  &:hover:not(:disabled) {
    border: 1px solid
      ${props => (props.checked ? props.theme.color.controlPrimaryHover : props.theme.color.controlPrimaryHover)};
  }
`;

/**
 * A radio button.
 */
export const Radio = styled(Input).attrs({ type: "radio" })<{ checked: boolean }>`
  -webkit-appearance: none;
  background-color: transparent;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  padding: 2px;
  margin: 0 8px 0 0;
  transition: all 80ms ease-out;
  border: 1px solid ${props => props.theme.color.bgBorderSolid};
  border-radius: 50%;
  box-shadow: none;
  position: relative;

  &:checked {
    background-color: ${props => props.theme.color.controlPrimary};
    border: 1px solid ${props => props.theme.color.controlPrimary};

    &:after {
      content: "";
      position: absolute;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      top: 5px;
      left: 5px;
      background-color: ${props => props.theme.color.controlLabel};
    }
  }

  &:hover:not(:disabled) {
    border: 1px solid ${props => (props.checked ? props.theme.color.controlPrimary : props.theme.color.labelFaint)};
  }
`;

// - Assets

const encodeDataURI = (data: string) =>
  encodeURI(data).replace(/#/g, "%23").replace(/\(/g, "%28").replace(/\)/g, "%29");

const caret = (color: string) =>
  encodeDataURI(
    `data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="color" xmlns="http://www.w3.org/2000/svg"><path d="M10.1611 0.314094L5.99463 4.48054L1.82819 0.314094C1.4094 -0.104698 0.732886 -0.104698 0.314094 0.314094C-0.104698 0.732886 -0.104698 1.4094 0.314094 1.82819L5.24295 6.75705C5.66174 7.17584 6.33825 7.17584 6.75705 6.75705L11.6859 1.82819C12.1047 1.4094 12.1047 0.732886 11.6859 0.314094C11.2671 -0.0939597 10.5799 -0.104698 10.1611 0.314094Z" transform="translate(0.77832 0.998535)"/></svg>`.replace(
      "color",
      color
    )
  );

const dash = (color: string) =>
  encodeDataURI(
    `data:image/svg+xml;utf8,<svg width="6" height="2" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="1.5" fill="color" /></svg>`.replace(
      "color",
      color
    )
  );

/** A checkmark SVG data URI in a provided color */
export const checkmark = (color: string) =>
  encodeDataURI(
    `data:image/svg+xml;utf8,<svg width="10" height="9" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" fill="color"><path d="M3.46975 5.70757L1.88358 4.1225C1.65832 3.8974 1.29423 3.8974 1.06897 4.1225C0.843675 4.34765 0.843675 4.7116 1.06897 4.93674L3.0648 6.93117C3.29006 7.15628 3.65414 7.15628 3.8794 6.93117L8.93103 1.88306C9.15633 1.65792 9.15633 1.29397 8.93103 1.06883C8.70578 0.843736 8.34172 0.843724 8.11646 1.06879C8.11645 1.0688 8.11643 1.06882 8.11642 1.06883L3.46975 5.70757Z" stroke-width="0.2" /></svg>`.replace(
      "color",
      color
    )
  );
