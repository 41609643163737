const CreateIcon = (props: {}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.7826 2.26359C14.1313 1.69123 14.0606 0.931152 13.5705 0.434915C13.0757 -0.0660681 12.3153 -0.134235 11.7483 0.230412C12.2123 1.09277 12.9198 1.79999 13.7826 2.26359Z" />
      <path d="M10.8171 1.11829L5.78472 6.18C6.77457 6.47517 7.57699 7.21908 7.95006 8.18198L12.9064 3.20208C12.0535 2.68436 11.3369 1.9696 10.8171 1.11829Z" />
      <path d="M6.91486 9.17614C6.80538 8.1186 5.91913 7.30487 4.8592 7.29183C4.53827 7.92804 4.21105 8.90848 4.01729 9.53109C3.93355 9.80017 4.16675 10.0527 4.44262 9.99047C5.16831 9.82683 6.36057 9.52165 6.91486 9.17614Z" />
      <path d="M3.75 1C1.67893 1 0 2.67893 0 4.75V10.25C0 12.3211 1.67893 14 3.75 14H10.2501C12.3212 14 14.0001 12.3211 14.0001 10.25V7C14.0001 6.58579 13.6643 6.25 13.2501 6.25C12.8359 6.25 12.5001 6.58579 12.5001 7V10.25C12.5001 11.4926 11.4927 12.5 10.2501 12.5H3.75C2.50736 12.5 1.5 11.4926 1.5 10.25V4.75C1.5 3.50736 2.50736 2.5 3.75 2.5H6C6.41421 2.5 6.75 2.16421 6.75 1.75C6.75 1.33579 6.41421 1 6 1H3.75Z" />
    </svg>
  );
};

export default CreateIcon;
