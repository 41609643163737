import React from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { LinearLoading } from "@linear/orbiter/components/LinearLoading";
import { Avatar } from "@linear/orbiter/components/Avatar";
import { Main } from "~/views/Main";
import type { FrontData } from "~/types";
import { AttachmentsQuery, type IAttachmentsQuery, type IViewerQuery, ViewerQuery } from "../queries";
import { Link } from "./Link";
import { ConversationTitle } from "./MessageFrom";
import { ErrorView } from "./ErrorView";

type Props = {
  /** Front conversation information. */
  data: FrontData;
  /** Callback to log the user out. */
  onLogout(): void;
};

export function Layout(props: Props) {
  const { data, onLogout } = props;

  const [viewerResult, reexecuteViewerQuery] = useQuery<IViewerQuery>({
    query: ViewerQuery,
    requestPolicy: "cache-first",
  });
  const [attachmentResult, reexecuteAttachmentQuery] = useQuery<IAttachmentsQuery>({
    query: AttachmentsQuery,
    requestPolicy: "cache-first",
    variables: {
      ticketUrl: data.url,
    },
  });

  // Reload the app when navigating between conversations
  React.useEffect(() => {
    reexecuteAttachmentQuery({ requestPolicy: "cache-and-network" });
  }, [data.conversationId]);

  const handleFetchAttachments = () => reexecuteAttachmentQuery({ requestPolicy: "network-only" });
  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    onLogout();
  };
  const handleReload = () => {
    reexecuteViewerQuery({ requestPolicy: "network-only" });
    handleFetchAttachments();
  };

  const error = viewerResult.error;
  if (error) {
    return <ErrorView error={error.message} onLogout={onLogout} onReload={handleReload} />;
  }

  const user = viewerResult.data?.viewer;
  const teams = viewerResult.data?.teams.nodes || [];
  const priorities = viewerResult.data?.issuePriorityValues || [];
  const attachments = attachmentResult.data?.attachmentsForURL.nodes || [];
  const projectAttachments = attachmentResult.data?.projectAttachments.nodes || [];
  const finishedFetching = viewerResult.data && !viewerResult.fetching && !attachmentResult.fetching;
  const canLinkProjects =
    viewerResult.data?.customerRequestsOnProjectsFlag.value && viewerResult.data?.organization.customersEnabled;

  return (
    <Container column auto>
      <LinearLoading loaded={!!finishedFetching} />
      <Content auto column gap={16}>
        {(data.subject || data.fromHandle || data.fromName) && (
          <ConversationTitle subject={data.subject} handle={data.fromHandle} name={data.fromName} />
        )}
        <Main
          key={data.conversationId}
          data={data}
          teams={teams}
          priorities={priorities}
          attachments={attachments}
          projectAttachments={projectAttachments}
          reloadData={handleFetchAttachments}
          canLinkProjects={!!canLinkProjects}
        />
      </Content>
      {user && (
        <Footer justify="space-between" shrink={0}>
          <Flex align="center" gap={8}>
            <Avatar src={user.avatarUrl} size="small" modelId={user.id} name={user.name} />
            <Text variant="smallPlus" color="labelTitle">
              {user.name}
            </Text>
          </Flex>
          <Flex align="center">
            <Link onClick={handleLogout}>Log out</Link>
          </Flex>
        </Footer>
      )}
    </Container>
  );
}

const Container = styled(Flex)`
  position: relative;
  max-height: 100%;
`;

const Content = styled(Flex)`
  padding: 24px 12px;
  overflow-y: auto;
`;

const Footer = styled(Flex)`
  height: 48px;
  padding: 0 12px;
  border-top: 1px solid ${props => props.theme.color.bgBorder};
`;
