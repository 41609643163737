import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";

type Props = {
  handle?: string;
  name?: string;
  subject?: string;
};

/**
 * Displays a title for a conversation. If it's a discussion it will display the subject, otherwise it will show the
 * sender name.
 */
export const ConversationTitle = (props: Props) => {
  return (
    <Container gap={8} align="center">
      <IconContainer align="center" justify="center">
        <FrontIcon />
      </IconContainer>
      <Text variant="smallPlus" color="labelMuted" truncate>
        {props.name || props.handle ? `Message from ${props.name || props.handle}` : props.subject}
      </Text>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 8px;

  border-radius: 4px;
  border: 1px solid ${props => props.theme.color.bgBorder};
  background: ${props => props.theme.color.bgShade};
`;

const IconContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: ${props => props.theme.color.bgBase};

  svg {
    height: 14px;
  }
`;

const FrontIcon = () => (
  <svg width="43" height="60" viewBox="0 0 43 60">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.668945 10.3526C0.668945 4.88097 5.10461 0.445312 10.5763 0.445312H19.7215H42.3464V14.5442C42.3464 17.28 40.1286 19.4979 37.3928 19.4979H24.4072C21.8341 19.5387 19.7566 21.6197 19.7215 24.1941V55.0309C19.7215 57.7668 17.5037 59.9846 14.7678 59.9846H0.668945V19.4979V10.3526Z"
      fill="#001B38"
    ></path>
    <circle
      cx="24.6034"
      cy="24.38"
      r="12.8605"
      transform="rotate(90 24.6034 24.38)"
      fill="url(#ic_r4HomeLogoSmall_6842_0)"
    ></circle>
    <circle
      opacity="0.5"
      cx="24.6034"
      cy="24.38"
      r="12.8605"
      transform="rotate(90 24.6034 24.38)"
      fill="url(#ic_r4HomeLogoSmall_6842_1)"
    ></circle>
    <defs>
      <linearGradient
        id="ic_r4HomeLogoSmall_6842_0"
        x1="14.9629"
        y1="33.4371"
        x2="34.4361"
        y2="14.0974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF0057" stopOpacity="0.16"></stop> <stop offset="0.861354" stopColor="#FF0057"></stop>
      </linearGradient>
      <linearGradient
        id="ic_r4HomeLogoSmall_6842_1"
        x1="14.9629"
        y1="33.4371"
        x2="34.4361"
        y2="14.0974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF0057" stopOpacity="0.16"></stop> <stop offset="0.861354" stopColor="#FF0057"></stop>
      </linearGradient>
    </defs>
  </svg>
);
