import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Button } from "@linear/orbiter/components/Button";
import CreateIcon from "~/icons/CreateIcon";
import LinkIcon from "~/icons/LinkIcon";
import { ProjectWidget } from "~/components/ProjectWidget";
import type { Attachment } from "../queries";
import type { Team, FrontData, Priority, ProjectAttachment } from "../types";
import { IssueWidget } from "../components/IssueWidget";
import { CreateForm } from "./CreateForm";
import { LinkTicket } from "./LinkTicket";

type View = "main" | "create" | "link";

type Props = {
  /** Front conversation information. */
  data: FrontData;
  teams: Team[];
  priorities: Priority[];
  attachments: Attachment[];
  projectAttachments: ProjectAttachment[];
  /** Reload attachment data. */
  reloadData(): void;
  /** Whether the user can link projects. */
  canLinkProjects: boolean;
};

export function Main(props: Props) {
  const { data, reloadData, teams, priorities, attachments, projectAttachments, canLinkProjects } = props;
  const [view, setView] = React.useState<View>("main");

  const handleReload = () => {
    reloadData();
    setView("main");
  };

  if (view === "create") {
    return (
      <CreateForm
        teams={teams}
        priorities={priorities}
        data={data}
        onCreate={handleReload}
        onCancel={() => setView("main")}
      />
    );
  }

  if (view === "link") {
    return <LinkTicket data={data} onLink={handleReload} onCancel={() => setView("main")} />;
  }

  return (
    <Flex column gap={18}>
      <Buttons>
        <Button variant="secondary" icon={<CreateIcon />} onClick={() => setView("create")}>
          Create Issue
        </Button>
        <Button variant="secondary" icon={<LinkIcon />} onClick={() => setView("link")}>
          {canLinkProjects ? "Link Issue or Project" : "Link Issue"}
        </Button>
      </Buttons>
      <Flex column gap={24}>
        {attachments.map(attachment => (
          <IssueWidget
            key={attachment.id}
            attachmentId={attachment.id}
            issue={attachment.issue}
            onUnlink={reloadData}
            allowUnlink
          />
        ))}
      </Flex>
      <Flex column gap={24}>
        {canLinkProjects &&
          projectAttachments?.map(attachment => (
            <ProjectWidget
              key={attachment.id}
              attachmentId={attachment.id}
              project={attachment.project}
              onUnlink={reloadData}
              allowUnlink
            />
          ))}
      </Flex>
    </Flex>
  );
}

const Buttons = styled.div`
  display: flex;
  column-gap: 16px;

  > button {
    flex: 1;
  }
`;
