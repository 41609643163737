import * as React from "react";
import styled from "styled-components";
import { Text } from "@linear/orbiter/components/Text";
import { DelayedMount } from "@linear/orbiter/components/DelayedMount";
import { Flex } from "./Flex";

type Props = {
  /** Whether to display the error inline. */
  inline?: boolean;
  /** The content to render. */
  children?: React.ReactNode | false;
  /** The delay in milliseconds before the error is displayed. */
  delay?: number;
};

/**
 * <InputError>{errorMessage}</InputError>
 */
export const InputError: React.FunctionComponent<Props> = (props: Props) => {
  const { inline, children, delay, ...rest } = props;

  if (!children) {
    return <></>;
  }

  const content = (
    <InnerError inline={inline} align="center" gap={4} {...rest}>
      <Text variant="micro" color="redText">
        {children}
      </Text>
    </InnerError>
  );

  if (delay) {
    return <DelayedMount delay={delay}>{content}</DelayedMount>;
  }

  return <>{content}</>;
};

/**
 * Styled inner error component--not meant to be used directly, but exposed for style targetting
 * since InputError is a functional component that can be null, and thus not targetable.
 */
export const InnerError = styled(Flex)<Props>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) both;

  ${props =>
    props.inline === true
      ? `background: ${props.theme.color.bgBase};
         padding: 0 6px;
         border-radius: 4px;
         position: absolute;
         right: 4px;
         top: 4px;
         bottom: 4px;
         line-height: 100%;
  `
      : `margin-top: 8px;
  `}
`;
