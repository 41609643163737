import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useClient, useMutation } from "urql";
import throttle from "lodash/throttle";
import Front from "@frontapp/plugin-sdk";
import { Button } from "@linear/orbiter/components/Button";
import { Text } from "@linear/orbiter/components/Text";
import { Toggle } from "@linear/orbiter/components/Toggle";
import { Flex } from "@linear/orbiter/components/Flex";
import { Input } from "@linear/orbiter/components/Input";
import { AsyncSelect, type SelectOption } from "~/components/Select";
import { attachmentForFrontConversation } from "~/utils/attachmentForFrontConversation";
import { hasImages } from "~/utils/formattingUtils";
import { ProjectWidget } from "~/components/ProjectWidget";
import {
  CombinedSearchQuery,
  CreateAttachmentMutation,
  CreateProjectAttachmentMutation,
  type ICombinedSearchQuery,
  type ICreateAttachmentMutation,
  type ICreateProjectAttachmentMutation,
  SearchIssueQuery,
} from "../queries";
import type { FrontData, Issue, Project } from "../types";
import { Constants } from "../constants";
import { IssueWidget } from "../components/IssueWidget";

const LinkSchema = Yup.object()
  .shape({
    issue: Yup.object().optional(),
    project: Yup.object().optional(),
  })
  .test("at-least-one-field", "At least one field is required", function (value) {
    const { issue, project } = value;
    return issue || project;
  });

type Props = {
  data: FrontData;
  onLink(): void;
  onCancel(): void;
};

interface FormValues {
  issue?: Issue;
  project?: Project;
  comment: string;
  includeComment?: boolean;
}

export function LinkTicket(props: Props) {
  const { data, onLink, onCancel } = props;
  const descriptionHasImages = data.messages.find(m => hasImages(m.body));
  const includeComment = localStorage.getItem(Constants.commentCacheKey) === "true" || false;

  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const [createProjectAttachmentResult, createProjectAttachment] = useMutation<ICreateProjectAttachmentMutation>(
    CreateProjectAttachmentMutation
  );

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (
      (!values.issue && !values.project) ||
      createAttachmentResult.fetching ||
      createProjectAttachmentResult.fetching
    ) {
      setSubmitting(false);
      return;
    }
    const commentBody = values.includeComment && values.comment?.length ? values.comment : undefined;

    if (values.issue) {
      const topic = await Front.addTopic(values.issue.url, `${values.issue.identifier} ${values.issue.title}`);
      localStorage.setItem(Constants.commentCacheKey, values.includeComment ? "true" : "false");

      await createAttachment({
        issueId: values.issue.id,
        ...attachmentForFrontConversation({ ...data, linkId: topic.id }),
        commentBody,
      });
    } else if (values.project) {
      const topic = await Front.addTopic(values.project.url, `${values.project.name}`);
      localStorage.setItem(Constants.commentCacheKey, values.includeComment ? "true" : "false");

      await createProjectAttachment({
        projectId: values.project.id,
        ...attachmentForFrontConversation({ ...data, linkId: topic.id }),
        noteBody: commentBody,
      });
    }
    setSubmitting(false);
    onLink();
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Flex column gap={10}>
        {descriptionHasImages && (
          <Text variant="mini" color="labelBase">
            <b>Note:</b> If this conversation is in a private inbox, Linear will not be able to download images while
            attaching this conversation to an issue due to Front API limitations.
          </Text>
        )}
        <Formik
          initialValues={{
            comment: "",
            includeComment,
          }}
          validationSchema={LinkSchema}
          onSubmit={handleSubmit}
        >
          {(form: FormikProps<FormValues>) => (
            <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
              <SearchDropdown
                onSelectIssue={value => {
                  void form.setFieldValue("issue", value);
                }}
                onSelectProject={value => {
                  void form.setFieldValue("project", value);
                }}
                autoFocus
                canLinkProjects
              />

              {form.values.issue && (
                <>
                  <IssueWidget issue={form.values.issue} />

                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Flex align="center" gap={12}>
                        <Toggle id="includeComment" {...field} />
                        <Text as="label" htmlFor="includeComment" variant="smallPlus">
                          Include comment
                        </Text>
                      </Flex>
                    )}
                  </FormikField>

                  {form.values.includeComment && (
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <StyledTextareaAutosize minRows={5} maxRows={10} stretched autoFocus {...field} />
                      )}
                    </FormikField>
                  )}
                </>
              )}

              {form.values.project && (
                <>
                  <ProjectWidget project={form.values.project} />

                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Flex align="center" gap={12}>
                        <Toggle id="includeComment" {...field} />
                        <Text as="label" htmlFor="includeComment" variant="smallPlus">
                          Include comment
                        </Text>
                      </Flex>
                    )}
                  </FormikField>

                  {form.values.includeComment && (
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <StyledTextareaAutosize minRows={5} maxRows={10} stretched autoFocus {...field} />
                      )}
                    </FormikField>
                  )}
                </>
              )}

              <Buttons>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={(form.values.issue === undefined && form.values.project === undefined) || form.isSubmitting}
                >
                  {form.isSubmitting ? "Linking…" : "Link ticket"}
                </Button>
                <Button variant="link" onClick={onCancel}>
                  Cancel
                </Button>
              </Buttons>
            </StyledForm>
          )}
        </Formik>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  padding: 4px;
  max-width: 100%;
  min-height: 350px;
  overflow: auto;
`;

const StyledForm = styled(Form)`
  > div,
  > textarea {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 12px;
  }
`;

interface SearchDropdownProps {
  canLinkProjects: boolean;
  onSelectIssue(issue: Issue): void;
  onSelectProject(project: Project): void;
  autoFocus?: boolean;
}

const issueCache: Record<Issue["id"], Issue | undefined> = {};
const projectCache: Record<Project["id"], Project | undefined> = {};
/**
 * Dropdown to search issues and projects
 */
const SearchDropdown = (props: SearchDropdownProps) => {
  const { onSelectIssue, onSelectProject, canLinkProjects, ...rest } = props;
  const client = useClient();
  // Used to track search input to only return latest search value when throttling input
  const lastSearchInput = React.useRef<string | undefined>();

  const fetchIssues = (
    inputValue: string,
    callback: (
      opts: {
        label: string;
        options: SelectOption[];
      }[]
    ) => void
  ) => {
    if (inputValue.length === 0) {
      callback([]);
    }

    lastSearchInput.current = inputValue;
    void client
      .query<ICombinedSearchQuery>(canLinkProjects ? CombinedSearchQuery : SearchIssueQuery, {
        searchQuery: inputValue,
        includeComments: true,
      })
      .toPromise()
      .then(result => {
        const issues: SelectOption[] =
          result.data?.searchIssues.nodes.map(issue => ({
            value: issue.id,
            label: `${issue.identifier} ${issue.title}`,
            type: "issue",
          })) || [];
        for (const issue of result.data?.searchIssues.nodes || []) {
          issueCache[issue.id] = issue;
        }

        const projects: SelectOption[] =
          result.data?.searchProjects?.nodes?.map(project => ({
            value: project.id,
            label: `${project.name}`,
            type: "project",
          })) || [];
        for (const project of result.data?.searchProjects?.nodes || []) {
          projectCache[project.id] = project;
        }

        if (inputValue === lastSearchInput.current) {
          callback([
            { label: "Issues", options: issues },
            { label: "Projects", options: projects },
          ]);
        }
      });
  };
  const debouncedFetch = throttle(fetchIssues, 250, { leading: true, trailing: true });

  return (
    <AsyncSelect
      {...rest}
      cacheOptions
      defaultOptions
      loadOptions={debouncedFetch}
      onChange={(data: SelectOption) => {
        switch (data.type) {
          case "issue":
            const issue = issueCache[data.value];
            if (issue) {
              onSelectIssue(issue);
            }
            break;
          case "project":
            const project = projectCache[data.value];
            if (project) {
              onSelectProject(project);
            }
            break;
          default:
            break;
        }
      }}
      noOptionsMessage={({ inputValue }) => (inputValue ? "No matching results found" : "Type to search…")}
    />
  );
};

const StyledTextareaAutosize = styled(Input).attrs({ as: TextareaAutosize })`
  appearance: none;
  -webkit-appearance: none;
  resize: none;
`;
