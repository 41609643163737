import * as React from "react";

const IssueIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M4.25 1C2.45507 1 1 2.45507 1 4.25V8.75C1 10.5449 2.45507 12 4.25 12H8.75C10.5449 12 12 10.5449 12 8.75V4.25C12 2.45507 10.5449 1 8.75 1H4.25ZM2.5 4.25C2.5 3.2835 3.2835 2.5 4.25 2.5H8.75C9.7165 2.5 10.5 3.2835 10.5 4.25V8.75C10.5 9.7165 9.7165 10.5 8.75 10.5H4.25C3.2835 10.5 2.5 9.7165 2.5 8.75V4.25Z"
      fill="#1B1B1B"
    />
    <path
      d="M5.0429 14.1356C4.5555 13.6845 4.98115 12.9999 5.64527 12.9999C5.8888 12.9999 6.11661 13.1054 6.32306 13.2346C6.59183 13.4027 6.90953 13.4999 7.24994 13.4999H11.7499C12.2268 13.4999 12.6591 13.3092 12.9747 12.9999H12.9999V12.9747C13.3092 12.6591 13.4999 12.2268 13.4999 11.7499V7.24994C13.4999 6.90953 13.4027 6.59183 13.2346 6.32306C13.1054 6.11661 12.9999 5.8888 12.9999 5.64527C12.9999 4.98115 13.6845 4.5555 14.1356 5.04289C14.6721 5.62248 14.9999 6.39792 14.9999 7.24994V11.7499C14.9999 13.5449 13.5449 14.9999 11.7499 14.9999H7.24994C6.39792 14.9999 5.62248 14.6721 5.0429 14.1356Z"
      fill="#1B1B1B"
    />
  </svg>
);
export default IssueIcon;
